<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col cols="12" class="text-center" mt-1>
        <v-icon>filter_center_focus</v-icon>
        <h3>Scan Receipt for Pickup</h3>
      </v-col>
      <template v-if="false">
        <v-col cols="12" class="text-center" mt-1>
          <v-btn color="primary" @click="scanning = !scanning" >
            <template v-if="scanning">
              <v-icon  dark>pause</v-icon>
            </template>
            <template v-else>
              <v-icon dark>play_arrow</v-icon>
            </template>
          </v-btn>
        </v-col>
      </template>
      <v-col cols="12" class="text-center" mt-3>
        <template>
          <qrcode-stream
            v-show="scanning"
            :camera="{ facingMode }" 
            @init="onInit"
            @decode="onDecode">
            <v-btn small center bottom @click="switchCamera">Switch Camera</v-btn>
          </qrcode-stream>
        </template>
        <template v-if="searching">
          <h3>Searching for order...</h3>
          <br>
          <v-progress-circular
            :size="70"
            :width="7"
            color="blue"
            indeterminate
          ></v-progress-circular>
        </template>
        <v-dialog v-model="dialog" persistent max-width="420">
          <template v-if="order">
            <template v-if="orderActive">
              <v-card>
                <v-card-title primary-title>
                  <div>
                    <div class="headline">{{order.name}} (x{{order.line_items[0].quantity}})</div>
                    <span>{{ order.line_items[0].variation_name}}</span>
                  </div>
                </v-card-title>
                <v-col cols="12" class="text-xs-left" ma-3>
                  <div class="subheading font-weight-light">Ask for ID, then select the person <strong>who is picking up:</strong></div>
                  <v-radio-group v-model="radios">
                    <v-radio value="member" color="blue"> 
                      <div slot="label"><strong class="blue--text">{{ user.name }} (Member #{{user.memberId}})</strong></div>
                    </v-radio>
                    <v-radio value="proxy" color="purple">
                      <div slot="label"><strong class="purple--text">{{user.proxy}} (Proxy)</strong></div>
                    </v-radio>
                  </v-radio-group>
                </v-col>
                <template v-if="multipleOrders">
                  <v-col cols="12" class="text-xs-left" ma-3>
                    
                    <div class="subheading font-weight-light">{{user.name.split(" ")[0]}} has more orders to pickup. Select all orders <strong>being picked up now:</strong></div>
                    <v-list two-line subheader>
                      <template v-for="(order, index) in allOrders">
                        <v-list-item
                          :key="order.name"
                        >
                          <v-list-item-action>
                            <v-checkbox
                              v-model="order.selected" 
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{order.name}} (x{{order.line_items[0].quantity}})</v-list-item-title>
                            <v-list-item-subtitle class="text--primary">{{ order.line_items[0].variation_name}}</v-list-item-subtitle>
                          </v-list-item-content>

                        </v-list-item>

                      </template>
                    </v-list>
                  </v-col>
                </template>
                <v-card-actions class="justify-left">
                  <v-btn color="light" @click="dialog = false; scanning = true" v-show="!loading">
                    <v-icon>close</v-icon>&nbsp;Cancel</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="completePickup" :loading="loading" :disabled="radios==''">
                    <v-icon>check</v-icon>&nbsp;Complete Pickup</v-btn>
                </v-card-actions>
              </v-card>
            </template>
            <template v-else>
              <v-card>
                <v-card-title class="justify-center" primary-title><h3 class="headline">Order Already Picked Up</h3></v-card-title>
                <v-card-text class="text-center">
                  {{pickupLine}} <br><br>
                  <strong>Picked up by {{ order.pickedUpBy}} on {{ formatDate(order.pickedUp)}}</strong>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="dialog = false; scanning = true">Cancel</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </template>
          </template>
          <template v-else>
            <v-card>
              <v-card-title class="justify-center" primary-title><h3 class="headline">Order Not Found</h3></v-card-title>
              <v-card-actions class="justify-center">
                <v-btn color="light" @click="dialog = false; scanning = true">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-dialog v-model="confirmation" persistent max-width="300">
          <v-card>
            <v-card-title class="justify-center" primary-title><h3 class="headline">PICKUP COMPLETE!</h3></v-card-title>
            <v-card-text>
              <li v-for="order in pickupOrders">
                {{"(" + order.line_items[0].quantity + ") " + order.name + ", " + order.line_items[0].variation_name }}
              </li>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn color="primary" @click="dialog = confirmation = false; scanning = true">Scan Next Order</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import api from '@/store/api'
import axios from 'axios'
const REAR_CAMERA = { ideal: 'environment' }
const FRONT_CAMERA = { exact: 'user' }
import moment from 'moment'
export default {

  data () { 
    return{
      scanning: true,
      searching: false,
      dialog: false,
      pendingOrders: [],
      radios: "",
      loading: false,
      confirmation: false,
      facingMode: REAR_CAMERA,    
      noFrontCamera: false,
      allOrders: null,
      pickupOrders: [],
    }
  },
  computed: {
    camera () {
      if(this.scanning)
        return {}
      else 
        return false
    },
    orderActive() { //checks that the order was found, retreived, and is not in status = completed
      return this.$store.state.adminOrder && this.$store.state.adminOrder[0].status != "Completed"
    },
    order () { //the order found after looking up the scanned order bar code
      if(this.$store.state.adminOrder)
        return this.$store.state.adminOrder[0]
      else return null
    },
    multipleOrders () {
      console.log("testing")
      return (this.allOrders != null && this.allOrders.length > 1)
    },
    user () {
      return this.$store.state.adminUser
    },
    pickupLine () {
      let temp = this.$store.state.adminOrder[0]
      let line = "(" + temp.line_items[0].quantity + ") " + temp.line_items[0].variation_name
      if(temp.line_items[0].quantity > 1)
        line += "s"
      line += " of " + temp.name
      return line
    }
  },

  components: {
    'qrcode-stream': QrcodeStream,
  },

  methods: {
    switchCamera () {
      if (this.facingMode === FRONT_CAMERA) {
        this.facingMode = REAR_CAMERA
      } else {
        this.facingMode = FRONT_CAMERA
      }
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        this.noFrontCamera = this.facingMode === FRONT_CAMERA
          && error.name === 'OverconstrainedError'

        console.error(error)
      }
    },
    onDecode (decodedString) {
      if(decodedString.length == 17){ //data.order.code = now.format("YYYYMMDDHHmmssSSS") //ordercode format}
        this.scanning = false
        this.searching = true
        let self = this
        api.getOrderAndOthers(decodedString)
        .then(function (locatedOrders){
          self.allOrders = locatedOrders
          self.allOrders[0].selected = true
          self.searching = false
          self.dialog = true
        })
        .catch(function (err){
          console.log(err)
        })
      }
      else{
        console.log("Picked up invalid signal")
      }
    },
    completePickup () {
  
      let customer = this.user.name
      if(this.radios == "proxy")
        customer = this.user.proxy

      this.pickupOrders = []
      this.allOrders.forEach( order => {
        if(order.selected)
          this.pickupOrders.push(order)
      })

      this.loading = true
      this.searching = true
      this.dialog = this.loading = false
      
      api.setOrdersAsCompleted(customer, this.pickupOrders, "Pickup")
      .then( () => {
        this.searching = false
        this.confirmation = true
      })
      .catch ( error => {
        this.searching = false
        console.log(error)
      })
    },
    formatDate (dateStr) {
      return moment(dateStr, "YYYY-MM-DD @HH:mm").format("M/D/YYYY h:mma")
    },
  }
}
</script>
